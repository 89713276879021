import NoProfilePicture from '../../assets/no_profile_image.png';
import { PaymentDeviceStatus } from '../../modules/payment-devices/redux/paymentDevices.types';
import { ITabProp } from '../components/app-horizontal-tabs/tabs.type';

export type CardAction =
  | 'change-status'
  | 'update-3ds'
  | 'clear-3ds'
  | 'load-card'
  | 'unload-card'
  | 'lock';

type ExportReportsType =
  | 'users'
  | 'accounts'
  | 'transactions'
  | 'cards'
  | 'events';

export type ConfType = {
  notesCharLength?: number;
  appName?: string;
  pluginName?: string;
  routesBlackList?: string[];
  profileDefaultImage?: any;
  firstDayOfWeekMonday?: boolean;
  cardActions?: Array<CardAction>;
  paymentDeviceStatuses?: Array<PaymentDeviceStatus>;
  // Allows to open multiple tabs without being logged out
  multipleTabsSession?: boolean;
  // Overrides option to export reports for entities
  exportReports?: ExportReportsType[];
  // Overrides tabs based on key in AppHorizontalTabs component
  overrideTabsForKey?: { [key: string]: (tabs: ITabProp[]) => ITabProp[] };
  findCompanyDisabled?: boolean;
  allowCreateManualCompany?: boolean;
  allowEditUbo?: boolean;
};

export type ConfItemType = {
  [key in keyof ConfType]?: ConfType[key];
};

const config: ConfType = {
  appName: 'Bankvault 2.0',
  routesBlackList: [],
  profileDefaultImage: NoProfilePicture,
  firstDayOfWeekMonday: true,
  cardActions: [
    'lock',
    'change-status',
    'update-3ds',
    'clear-3ds',
    'load-card',
    'unload-card',
  ],
  exportReports: ['users', 'transactions'],
};

export default config;
